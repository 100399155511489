<template>
  <div @click="onSelectStep">
    <div v-if="step.status === StepStatus.valid && selectedStep && step.id != selectedStep.id">
      <a class="relative flex items-start" :class="[disabled ? '' : 'group cursor-pointer']">
        <span class="h-9 flex items-center">
          <span
            class="relative z-10 w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-blue-800"
          >
            <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
          </span>
        </span>
        <span class="ml-4 min-w-0 flex flex-col">
          <span class="text-xs font-semibold tracking-wide uppercase">{{ step.name }}</span>
          <span class="text-sm text-gray-500">{{ step.description }}</span>
        </span>
      </a>
    </div>
    <div v-else-if="selectedStep && step.id === selectedStep.id">
      <a class="relative flex items-start group" aria-current="step">
        <span class="h-9 flex items-center" aria-hidden="true">
          <span
            class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full"
          >
            <span class="h-2.5 w-2.5 bg-blue-600 rounded-full" />
          </span>
        </span>
        <span class="ml-4 min-w-0 flex flex-col">
          <span class="text-xs font-semibold tracking-wide uppercase text-blue-600">{{ step.name }}</span>
          <span class="text-sm text-gray-500">{{ step.description }}</span>
        </span>
      </a>
    </div>
    <div v-else-if="(step.status === StepStatus.untouched) | (step.status === StepStatus.invalid)">
      <a class="relative flex items-start" :class="[disabled ? '' : 'group cursor-pointer']">
        <span class="h-9 flex items-center" aria-hidden="true">
          <span
            class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400"
          >
            <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
          </span>
        </span>
        <span class="ml-4 min-w-0 flex flex-col">
          <span class="text-xs font-semibold tracking-wide uppercase text-gray-500">{{ step.name }}</span>
          <span class="text-sm text-gray-500">{{ step.description }}</span>
        </span>
      </a>
    </div>
    <!-- TODO: Eigene Darstellung für Invalid -->
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
//
import { CheckIcon } from "@heroicons/vue/solid";
// other
import { Step, StepStatus } from "./useSteps";

export default defineComponent({
  name: "StepsVertical",
  components: { CheckIcon },
  props: {
    step: { type: Object as PropType<Step>, required: true },
    selectedStep: { type: Object as PropType<Step>, default: null },
    disabled: { type: Boolean, default: false }
  },
  emits: { "step-selected": null },
  setup(props, context) {
    // select step if not disabled or currently selected
    const onSelectStep = () => {
      if (props.disabled === false && props.selectedStep.id != props.step.id) context.emit("step-selected");
    };

    return { StepStatus, onSelectStep };
  }
});
</script>
