
import { defineComponent } from "vue";
// components
import StepsVertical from "@/components/steps/StepsVertical.vue";
import ScheduleFormStepGeneral from "@/features/schedules/form/ScheduleFormStepGeneral.vue";
import ScheduleFormStepWorkAreas from "@/features/schedules/form/ScheduleFormStepWorkAreas.vue";
import ScheduleFormStepEmployees from "@/features/schedules/form/ScheduleFormStepEmployees.vue";
import ScheduleFormStepDemand from "@/features/schedules/form/ScheduleFormStepDemand.vue";
import ScheduleFormStepFinalize from "@/features/schedules/form/ScheduleFormStepFinalize.vue";

// other
import useScheduleForm from "./useScheduleForm";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ScheduleFormWizard",
  components: {
    StepsVertical,
    ScheduleFormStepGeneral,
    ScheduleFormStepWorkAreas,
    ScheduleFormStepEmployees,
    ScheduleFormStepDemand,
    ScheduleFormStepFinalize
  },
  setup() {
    const { steps, selectedStep, selectStep, initializeScheduleForm, workAreaIds } = useScheduleForm();

    const onSelectStep = (stepId: string) => {
      selectStep(stepId);
      console.log("select", stepId);
    };

    const router = useRouter();

    const onReset = () => {
      initializeScheduleForm();
      router.push({ name: "schedules" });
    };

    return { workAreaIds, steps, selectedStep, onSelectStep, onReset };
  }
});
