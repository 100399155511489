
import { defineComponent, watch, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import DemandTemplateTable from "@/features/demandTemplates/table/DemandTemplateTable.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
import useWorkAreaStore from "@/store/useWorkAreaStore";
// other
import useScheduleForm from "@/features/schedules/form/useScheduleForm";
import { StepStatus } from "@/components/steps/useSteps";

export default defineComponent({
  name: "ScheduleForm",
  components: {
    DetailsSection,
    DemandTemplateTable
  },
  props: { stepId: String },
  emits: {
    "reset-form": null
  },
  setup(props, context) {
    //store
    const { demandTemplates } = useDemandTemplateStore({ workAreaId: props.stepId, allDemandTemplates: true });
    const { workArea } = useWorkAreaStore({ workAreaId: props.stepId });

    // schedule form hook
    const {
      selectedStep,
      completeSelectedStep,
      selectPreviousStep,
      demandTemplateIds,
      addDemandTemplate,
      removeDemandTemplate,
      setSelectedStepInvalid,
      setSelectedStepValid
    } = useScheduleForm();

    // get demand template ids of current work area
    const workAreaDemandTemplateIds = computed(() =>
      demandTemplates.value
        .filter(demandTemplate => demandTemplateIds.value.includes(demandTemplate.id))
        .map(demandTemplate => demandTemplate.id)
    );

    // check step validity, invalid if no employees are selected
    const checkStepValidity = () => {
      if (workAreaDemandTemplateIds.value.length === 0) {
        // set error
        setSelectedStepInvalid();
        // set valid
      } else setSelectedStepValid();
    };

    // initialize demandTemplateIds, if no work areas are selected yet
    const initialiteDemandTemplates = () => {
      if (workAreaDemandTemplateIds.value.length === 0) {
        // select all work areas
        demandTemplates.value.forEach(demandTemplate => addDemandTemplate(demandTemplate.id));
      }
      checkStepValidity();
    };
    // initialize on component load
    initialiteDemandTemplates();
    // initialize when work areas change
    watch(demandTemplates, () => {
      initialiteDemandTemplates();
    });

    // remove or add employee to work area
    const onSelectDemandTemplate = (demandTemplateId: string) => {
      // remove
      if (demandTemplateIds.value?.includes(demandTemplateId)) {
        removeDemandTemplate(demandTemplateId);
        // add
      } else {
        addDemandTemplate(demandTemplateId);
      }
    };

    // set/unset step error, if no work areas are selected
    watch(demandTemplateIds, () => {
      checkStepValidity();
    });

    /**
     * General
     */

    const hasError = computed(() => selectedStep.value?.status === StepStatus.invalid);

    // Validate and create schedule
    const onNext = () => {
      completeSelectedStep();
    };

    // close form and reset data
    const onClose = () => {
      context.emit("reset-form");
    };

    return {
      onNext,
      onClose,
      hasError,
      demandTemplates,
      demandTemplateIds,
      selectPreviousStep,
      onSelectDemandTemplate,
      workArea
    };
  }
});
