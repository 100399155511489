<template>
  <div class="flex flex-col sm:flex-row w-full">
    <!-- {{ steps }} -->
    <div class="flex-shrink-0 w-72">
      <StepsVertical :steps="steps" :selectedStep="selectedStep" @step-selected="onSelectStep" />
    </div>
    <div class="flex w-full">
      <ScheduleFormStepGeneral v-if="steps.length > 0 && selectedStep.id === steps[0].id" @reset-form="onReset" />
      <ScheduleFormStepWorkAreas v-if="steps.length > 1 && selectedStep.id === steps[1].id" @reset-form="onReset" />
      <ScheduleFormStepEmployees v-if="steps.length > 2 && selectedStep.id === steps[2].id" @reset-form="onReset" />
      <template v-for="workAreaId in workAreaIds" :key="workAreaId">
        <ScheduleFormStepDemand v-if="selectedStep.id === workAreaId" :stepId="workAreaId" @reset-form="onReset" />
      </template>
      <ScheduleFormStepFinalize
        v-if="steps.length > 1 && selectedStep.id === steps[steps.length - 1].id"
        @reset-form="onReset"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import StepsVertical from "@/components/steps/StepsVertical.vue";
import ScheduleFormStepGeneral from "@/features/schedules/form/ScheduleFormStepGeneral.vue";
import ScheduleFormStepWorkAreas from "@/features/schedules/form/ScheduleFormStepWorkAreas.vue";
import ScheduleFormStepEmployees from "@/features/schedules/form/ScheduleFormStepEmployees.vue";
import ScheduleFormStepDemand from "@/features/schedules/form/ScheduleFormStepDemand.vue";
import ScheduleFormStepFinalize from "@/features/schedules/form/ScheduleFormStepFinalize.vue";

// other
import useScheduleForm from "./useScheduleForm";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "ScheduleFormWizard",
  components: {
    StepsVertical,
    ScheduleFormStepGeneral,
    ScheduleFormStepWorkAreas,
    ScheduleFormStepEmployees,
    ScheduleFormStepDemand,
    ScheduleFormStepFinalize
  },
  setup() {
    const { steps, selectedStep, selectStep, initializeScheduleForm, workAreaIds } = useScheduleForm();

    const onSelectStep = (stepId: string) => {
      selectStep(stepId);
      console.log("select", stepId);
    };

    const router = useRouter();

    const onReset = () => {
      initializeScheduleForm();
      router.push({ name: "schedules" });
    };

    return { workAreaIds, steps, selectedStep, onSelectStep, onReset };
  }
});
</script>
