<template>
  <DetailsSection
    :header="'General information'"
    :headerCaption="'General information for the schedule'"
    :hasFullWidth="true"
  >
    <template #body>
      <div class="max-w-sm">
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <DatePickerInput
              :name="'startDate'"
              :label="'Start date'"
              :validate="validateSchema"
              :schema="scheduleSchema.fields.startDate"
              @select-date="onSelectStartDate"
              :initialDate="startDate"
              :selectedDate="startDate"
              :firstDisabledDate="endDate"
            />
          </div>
          <div class="col-span-4 sm:col-span-2">
            <DatePickerInput
              :name="'endDate'"
              :label="'End date'"
              :disabled="startDate == null"
              :validate="validateSchema"
              :schema="scheduleSchema.fields.endDate"
              @select-date="onSelectEndDate"
              :initialDate="endDate"
              :selectedDate="endDate"
              :lastDisabledDate="startDate"
            />
          </div>
        </div>
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-4 flex items-center">
            <BaseInput
              v-model="scheduleName"
              @input="onChangeName"
              :name="'name'"
              :label="'Name'"
              :validate="validateSchema"
              :schema="scheduleSchema.fields.name"
              :focus="false"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-between">
        <BaseButton class="mr-3" @btn-click="onClose" :variant="'default'">Cancel</BaseButton>
        <BaseButton :variant="'primary'" @btn-click="onNext">Next</BaseButton>
      </div>
    </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import DatePickerInput from "@/components/datepicker/DatePickerInput.vue";
// other
import useScheduleForm from "@/features/schedules/form/useScheduleForm";

export default defineComponent({
  name: "ScheduleForm",
  components: {
    DetailsSection,
    DatePickerInput
  },
  emits: {
    "reset-form": null
  },
  setup(props, context) {
    // schedule form hook
    const {
      completeSelectedStep,
      validateSchema,
      setVaidateSchema,
      scheduleSchema,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      name,
      setName
    } = useScheduleForm();

    // update name
    const scheduleName = ref(name.value);
    watch(name, newName => {
      scheduleName.value = newName;
    });
    const onChangeName = () => setName(scheduleName.value);

    // update dates
    const onSelectStartDate = (startDate: Date) => {
      setStartDate(startDate);
    };
    const onSelectEndDate = (endDate: Date) => {
      setEndDate(endDate);
    };

    // Validate and create schedule
    const onNext = () => {
      const formData = { name: scheduleName.value, startDate: startDate.value, endDate: endDate.value };
      scheduleSchema
        .validate(formData)
        .then(() => {
          completeSelectedStep();
        })
        .catch(e => {
          setVaidateSchema(true);
          console.log(e);
        });
    };

    // close form and reset data
    const onClose = () => {
      setVaidateSchema(false);
      context.emit("reset-form");
    };

    return {
      validateSchema,
      scheduleSchema,
      startDate,
      endDate,
      scheduleName,
      onSelectStartDate,
      onSelectEndDate,
      onNext,
      onClose,
      onChangeName
    };
  }
});
</script>
