
import { defineComponent, PropType } from "vue";
//
import { CheckIcon } from "@heroicons/vue/solid";
// other
import { Step, StepStatus } from "./useSteps";

export default defineComponent({
  name: "StepsVertical",
  components: { CheckIcon },
  props: {
    step: { type: Object as PropType<Step>, required: true },
    selectedStep: { type: Object as PropType<Step>, default: null },
    disabled: { type: Boolean, default: false }
  },
  emits: { "step-selected": null },
  setup(props, context) {
    // select step if not disabled or currently selected
    const onSelectStep = () => {
      if (props.disabled === false && props.selectedStep.id != props.step.id) context.emit("step-selected");
    };

    return { StepStatus, onSelectStep };
  }
});
