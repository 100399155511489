<template>
  <DetailsSection :header="'Summary'" :headerCaption="'Schedule summary'" :hasFullWidth="true">
    <template #body> </template>
    <template #footer>
      <div class="flex w-full justify-between">
        <BaseButton class="mr-3" @btn-click="onClose" :variant="'default'">Cancel</BaseButton>
        <div>
          <BaseButton class="mr-3" @btn-click="selectPreviousStep" :variant="'default'">Back</BaseButton>
          <BaseButton :variant="'primary'" @btn-click="onCreate">Create schedule</BaseButton>
        </div>
      </div>
    </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
// store
import useDemandTemplateStore from "@/store/useDemandTemplateStore";
import useScheduleStore from "@/store/useScheduleStore";
// other
import useScheduleForm from "@/features/schedules/form/useScheduleForm";
import { createDatesFromRecurrencePattern } from "@/features/recurrencePattern/useRecurrencePattern";
import { parseTimeString } from "@/utils/dateHelpers";
import { useRouter } from "vue-router";
import { cleanUuid } from "@/utils/globalHelpers";

import {
  ScheduleEmployeeInsertInput,
  ScheduleWorkAreaInsertInput,
  DemandTemplateInsertInput,
  DemandParentInsertInput,
  DemandInsertInput,
  RecurrencePatternInsertInput
} from "@/graphql/types";

export default defineComponent({
  name: "ScheduleForm",
  components: {
    DetailsSection
  },
  emits: {
    "reset-form": null
  },
  setup(props, context) {
    // close form and reset data
    const onClose = () => {
      context.emit("reset-form");
    };

    // schedule form hook
    const {
      selectPreviousStep,
      name,
      startDate,
      endDate,
      employeeIds,
      workAreaIds,
      demandTemplateIds
    } = useScheduleForm();

    // dynamic options for schedule store
    const scheduleStoreOptions = reactive({ allSchedules: true, scheduleId: undefined });

    const {
      tenantId,
      onCreateSchedule,
      onCreateScheduleSuccess,
      onCreateDemands,
      onCreateDemandsSuccess,
      selectedSchedule,
      setDate
    } = useScheduleStore(scheduleStoreOptions);

    const { demandTemplates } = useDemandTemplateStore({
      allDemandTemplates: true,
      demandTemplateIds: demandTemplateIds.value
    });

    // create schedule based on form data
    const onCreate = () => {
      /**
       * Prepare data
       */

      // general data
      const startDateInput = startDate.value ? startDate.value : new Date();
      const endDateInput = endDate.value ? endDate.value : new Date();
      // employees
      const employeeInput: ScheduleEmployeeInsertInput[] = employeeIds.value.map(id => {
        return { employee_id: id, tenant_id: tenantId.value };
      });
      // work areas
      const workAreaInput: ScheduleWorkAreaInsertInput[] = workAreaIds.value.map(id => {
        return { work_area_id: id, tenant_id: tenantId.value };
      });
      // demand templates
      const demandTemplateInput: DemandTemplateInsertInput[] = demandTemplates.value.map(template => {
        // create demand parents
        const demandParentInput: DemandParentInsertInput[] = template.demand_parents.map(parent => {
          const demandParentInsertInput: DemandParentInsertInput = {
            start_time: parent.start_time,
            end_time: parent.end_time,
            amount: parent.amount,
            work_area_id: parent.work_area_id,
            tenant_id: tenantId.value
          };
          return demandParentInsertInput;
        });
        // create recurrence patterns
        const recurrencePatternInput: RecurrencePatternInsertInput[] = template.recurrence_patterns.map(pattern => {
          const recurrencePatternInsertInput: RecurrencePatternInsertInput = {
            freq: pattern.freq,
            interval: pattern.interval,
            byweekday: pattern.byweekday,
            bymonth: pattern.bymonth,
            bysetpos: pattern.bysetpos,
            dtstart: pattern.dtstart,
            bydates: pattern.bydates,
            tenant_id: tenantId.value
          };
          return recurrencePatternInsertInput;
        });
        // create demand template
        const demandTemplateInsertInput: DemandTemplateInsertInput = {
          name: template.name,
          description: template.description,
          work_area_id: template.work_area_id,
          tenant_id: tenantId.value,
          demand_parents: { data: demandParentInput },
          recurrence_patterns: { data: recurrencePatternInput }
        };
        return demandTemplateInsertInput;
      });

      /**
       * Execute mutation
       */
      onCreateSchedule(name.value, startDateInput, endDateInput, employeeInput, workAreaInput, demandTemplateInput);
    };

    // create demand objects for schedule
    const createDemandList = () => {
      // base data
      const scheduleId = selectedSchedule.value?.id;
      const tenantId = selectedSchedule.value?.tenant_id;
      // demand list
      const demandList: DemandInsertInput[] = [];
      // create demands for each demand template
      demandTemplates.value.forEach(template => {
        // work area id of demand template
        const workAreaId = template.work_area_id;
        // iterate recurrence patterns
        template.recurrence_patterns.forEach(pattern => {
          // list of dates relevant for recurrence pattern
          // eslint-disable-next-line
          const dateList = createDatesFromRecurrencePattern(startDate.value!, endDate.value!, pattern);
          // iterate demand parents, create demand for each parent and each recurrence pattern
          template.demand_parents.forEach(parent => {
            // create demand for each date
            dateList.forEach(date => {
              const demand: DemandInsertInput = {
                start_datetime: parseTimeString(parent.start_time, date),
                end_datetime: parseTimeString(parent.end_time, date),
                amount: parent.amount,
                work_area_id: workAreaId,
                demand_parent_id: parent.id,
                schedule_id: scheduleId,
                tenant_id: tenantId
              };
              // add to demand list
              demandList.push(demand);
            });
          });
        });
      });
      return demandList;
    };

    // create demands after schedule is created
    onCreateScheduleSuccess(() => {
      // update query for created schedule
      scheduleStoreOptions.scheduleId = selectedSchedule.value?.id;
      const demandList = createDemandList();
      // wait until schedule query is updated
      setTimeout(() => {
        onCreateDemands(demandList);
      }, 500);
    });

    const router = useRouter();
    // navigate to new schedule after demand was created
    onCreateDemandsSuccess(() => {
      // set selected date to start date of schedule
      // eslint-disable-next-line
      setDate(startDate.value!);
      // reset form !!! navigates to schedules !!!
      onClose();
      // navigate to new schedule
      const scheduleId = cleanUuid(selectedSchedule.value?.id);
      router.push({ path: `/schedules/${scheduleId}` });
    });

    return {
      selectPreviousStep,
      onCreate,
      onClose
    };
  }
});
</script>

/** * Test data */ // const scheduleData = { // name: "Test", // startDate: new Date("2021-07-11T13:01:31.529Z"), //
endDate: new Date("2021-07-15T13:01:31.529Z"), // workAreaIds: ["7376d38d-d548-48b5-8d4f-70ddc52d7b8b",
"e07640d5-bf05-460a-bb0d-aa56ff697847"], // employeeIds: ["6b5ef9e3-4b0d-4522-8cbd-397e953845f3",
"b2816d49-8af6-49fc-9716-0dd73d60fdff"], // demandTemplateIds: [ // "1c65a302-510a-4ebe-a514-67de257f09d7", //
"23038f5f-19ae-40cf-ad88-2b283383f583", // "000e866d-fde7-4daf-a90a-201f5d6d6457", //
"98eddd7a-9f89-4b1a-b46d-cf4c9b538996" // ], // stepState: { // steps: [ // { // id: "generalInformation", // name:
"General information", // description: "Enter general information.", // status: "valid" // }, // { id: "workAreas",
name: "Work areas", description: "Select work areas.", status: "valid" }, // { id: "employees", name: "Employees",
description: "Select employees.", status: "valid" }, // { // id: "7376d38d-d548-48b5-8d4f-70ddc52d7b8b", // name:
"Demand Gruppe 2", // description: "Select demand templates", // status: "valid" // }, // { // id:
"e07640d5-bf05-460a-bb0d-aa56ff697847", // name: "Demand Gruppe 4", // description: "Select demand templates", //
status: "valid" // }, // { id: "finalize", name: "Summary", description: "Schedule summary.", status: "untouched" } //
], // selectedStep: { id: "finalize", name: "Summary", description: "Schedule summary.", status: "untouched" } // } //
}; // const demandList: DemandInsertInput[] = [ // { // start_datetime: "2021-07-14T04:00:00.000Z", // end_datetime:
"2021-07-14T16:00:00.000Z", // amount: 1, // work_area_id: "7376d38d-d548-48b5-8d4f-70ddc52d7b8b", // demand_parent_id:
"b9517c34-3676-4d4c-93d8-946b7bed052f", // schedule_id: "31dec114-b2c6-4e95-aa13-5907c70232ae", // tenant_id:
"dfcf8500-9aa0-4f16-b589-dd3ff35c78ef" // }, // { // start_datetime: "2021-07-14T04:00:00.000Z", // end_datetime:
"2021-07-14T14:45:00.000Z", // amount: 2, // work_area_id: "7376d38d-d548-48b5-8d4f-70ddc52d7b8b", // demand_parent_id:
"b9517c34-3676-4d4c-93d8-946b7bed052f", // schedule_id: "31dec114-b2c6-4e95-aa13-5907c70232ae", // tenant_id:
"dfcf8500-9aa0-4f16-b589-dd3ff35c78ef" // }, // { // start_datetime: "2021-07-14T05:15:00.000Z", // end_datetime:
"2021-07-14T12:15:00.000Z", // amount: 3, // work_area_id: "7376d38d-d548-48b5-8d4f-70ddc52d7b8b", // demand_parent_id:
"b9517c34-3676-4d4c-93d8-946b7bed052f", // schedule_id: "31dec114-b2c6-4e95-aa13-5907c70232ae", // tenant_id:
"dfcf8500-9aa0-4f16-b589-dd3ff35c78ef" // }, // { // start_datetime: "2021-07-14T04:00:00.000Z", // end_datetime:
"2021-07-14T16:00:00.000Z", // amount: 1, // work_area_id: "e07640d5-bf05-460a-bb0d-aa56ff697847", // demand_parent_id:
"b9517c34-3676-4d4c-93d8-946b7bed052f", // schedule_id: "31dec114-b2c6-4e95-aa13-5907c70232ae", // tenant_id:
"dfcf8500-9aa0-4f16-b589-dd3ff35c78ef" // } // ];
