import { ref, reactive, computed } from "vue";
import * as yup from "yup";
// other
import useSteps, { Step, StepStatus, StepState, createInitialStepState } from "@/components/steps/useSteps";

import {
  WorkArea
  // ScheduleEmployeeInsertInput,
  // ScheduleWorkAreaInsertInput,
  // ScheduleInsertInput
} from "@/graphql/types";

export interface ScheduleFormState {
  // general
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  // work areas, employees, demand templates
  workAreaIds: string[];
  employeeIds: string[];
  demandTemplateIds: string[];
  // steps
  stepState: StepState;
}

// Initialize time intercals state
const state: ScheduleFormState = reactive({
  // general
  name: "",
  startDate: null,
  endDate: null,
  // work areas, employees, demand templates
  workAreaIds: [],
  employeeIds: [],
  demandTemplateIds: [],
  // steps
  stepState: { steps: [], selectedStep: null }
});

// initial steps
const initialSteps: Step[] = [
  {
    id: "generalInformation",
    name: "General information",
    description: "Enter general information.",
    status: StepStatus.untouched
  },
  {
    id: "workAreas",
    name: "Work areas",
    description: "Select work areas.",
    status: StepStatus.untouched
  },
  {
    id: "employees",
    name: "Employees",
    description: "Select employees.",
    status: StepStatus.untouched
  },
  {
    id: "finalize",
    name: "Summary",
    description: "Schedule summary.",
    status: StepStatus.untouched
  }
];

export default function useScheduleForm() {
  // create steps hook
  const {
    setState: setStepState,
    selectStep,
    completeSelectedStep,
    selectPreviousStep,
    addStep,
    removeStep,
    setSelectedStepInvalid,
    setSelectedStepValid
  } = useSteps(state.stepState);

  // initialize form state
  const initializeScheduleForm = () => {
    // general
    state.name = "";
    state.startDate = null;
    state.endDate = null;
    // work areas, employees, demand templates
    state.workAreaIds = [];
    state.employeeIds = [];
    state.demandTemplateIds = [];
    // steps (deep copy of initial steps)
    state.stepState = createInitialStepState(JSON.parse(JSON.stringify(initialSteps)));
    state.stepState.selectedStep = state.stepState.steps[0];
    setStepState(state.stepState);
  };

  /**
   * General
   */

  // schema
  const validateSchema = ref(false);
  const scheduleSchema = yup.object({
    name: yup.string().required("Please enter a name"),
    startDate: yup
      .string()
      .required("Please enter the start date")
      .typeError("Please enter the start date"),
    endDate: yup
      .string()
      .required("Please enter the end date")
      .typeError("Please enter the start date")
  });

  const setStartDate = (date: Date) => (state.startDate = date);
  const setEndDate = (date: Date) => (state.endDate = date);
  const setName = (name: string) => (state.name = name);
  const setVaidateSchema = (validate: boolean) => (validateSchema.value = validate);

  /**
   * Steps
   */

  // create initial step
  if (state.stepState.steps.length === 0) initializeScheduleForm();

  /**
   * Work areas
   */

  // add work area step
  const addWorkAreaStep = (workAreas: WorkArea[], workAreaId: string) => {
    // get ordered list of work area ids
    const workAreaIdsOrdered = workAreas
      .filter(workArea => state.workAreaIds.includes(workArea.id))
      .map(workArea => workArea.id);
    // get index of work area
    const workAreaIdx = workAreaIdsOrdered.indexOf(workAreaId);
    // get work area
    const workArea = workAreas.find(workArea => workArea.id === workAreaId);
    // create work area step
    const workAreaStep: Step = {
      id: workArea?.id,
      name: `Demand ${workArea?.name}`,
      description: "Select demand templates",
      status: StepStatus.untouched
    };
    // add step, +3 because of basic steps
    addStep(workAreaStep, workAreaIdx + 3);
  };

  // add work area (if not already inserted)
  const addWorkArea = (workAreaId: string, workAreasUntyped: any) => {
    const workAreas: WorkArea[] = workAreasUntyped;
    if (state.workAreaIds.indexOf(workAreaId) === -1) {
      // add to id list
      state.workAreaIds = [...state.workAreaIds, workAreaId];
      // add step
      addWorkAreaStep(workAreas, workAreaId);
    }
  };

  // remove work area
  const removeWorkArea = (workAreaId: string) => {
    // remove id
    state.workAreaIds = state.workAreaIds.filter(id => id !== workAreaId);
    // remove step
    removeStep(workAreaId);
  };

  /**
   * Employees
   */

  // add employee
  const addEmployee = (employeeId: string) => {
    if (state.employeeIds.indexOf(employeeId) === -1) {
      state.employeeIds = [...state.employeeIds, employeeId];
    }
  };

  // remove emplyee
  const removeEmployee = (employeeId: string) => {
    state.employeeIds = state.employeeIds.filter(id => id !== employeeId);
  };

  /**
   * Demand templates
   */

  // add demandTemplate
  const addDemandTemplate = (demandTemplateId: string) => {
    if (state.demandTemplateIds.indexOf(demandTemplateId) === -1) {
      state.demandTemplateIds = [...state.demandTemplateIds, demandTemplateId];
    }
  };

  // remove emplyee
  const removeDemandTemplate = (demandTemplateId: string) => {
    state.demandTemplateIds = state.demandTemplateIds.filter(id => id !== demandTemplateId);
  };

  return {
    initializeScheduleForm,
    // general schema
    validateSchema: computed(() => validateSchema.value),
    setVaidateSchema,
    scheduleSchema,
    name: computed(() => state.name),
    setName,
    startDate: computed(() => state.startDate),
    setStartDate,
    endDate: computed(() => state.endDate),
    setEndDate,
    // steps
    steps: computed(() => state.stepState.steps),
    selectedStep: computed(() => state.stepState.selectedStep),
    completeSelectedStep,
    selectStep,
    selectPreviousStep,
    setSelectedStepInvalid,
    setSelectedStepValid,
    // work areas
    workAreaIds: computed(() => state.workAreaIds),
    addWorkArea,
    removeWorkArea,
    // employees
    employeeIds: computed(() => state.employeeIds),
    addEmployee,
    removeEmployee,
    // demand templates
    demandTemplateIds: computed(() => state.demandTemplateIds),
    addDemandTemplate,
    removeDemandTemplate
  };
}

// {"name":"Test","startDate":"2021-07-10T11:55:48.584Z","endDate":"2021-07-10T11:55:48.584Z","workAreaIds":["7376d38d-d548-48b5-8d4f-70ddc52d7b8b","e07640d5-bf05-460a-bb0d-aa56ff697847"],"employeeIds":["6b5ef9e3-4b0d-4522-8cbd-397e953845f3","b2816d49-8af6-49fc-9716-0dd73d60fdff"],"demandTemplateIds":["1c65a302-510a-4ebe-a514-67de257f09d7","23038f5f-19ae-40cf-ad88-2b283383f583","000e866d-fde7-4daf-a90a-201f5d6d6457","98eddd7a-9f89-4b1a-b46d-cf4c9b538996"],"stepState":{"steps":[{"id":"generalInformation","name":"General information","description":"Enter general information.","status":"valid"},{"id":"workAreas","name":"Work areas","description":"Select work areas.","status":"valid"},{"id":"employees","name":"Employees","description":"Select employees.","status":"valid"},{"id":"7376d38d-d548-48b5-8d4f-70ddc52d7b8b","name":"Demand Gruppe 2","description":"Select demand templates","status":"valid"},{"id":"e07640d5-bf05-460a-bb0d-aa56ff697847","name":"Demand Gruppe 4","description":"Select demand templates","status":"valid"},{"id":"finalize","name":"Summary","description":"Schedule summary.","status":"untouched"}],"selectedStep":{"id":"finalize","name":"Summary","description":"Schedule summary.","status":"untouched"}}}
