<template>
  <nav aria-label="Progress">
    <ol class="overflow-hidden">
      <li
        v-for="(step, stepIdx) in steps"
        :key="step.id"
        :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']"
      >
        <div
          v-if="stepIdx !== steps.length - 1"
          class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full"
          :class="[
            step.status === StepStatus.valid || steps[stepIdx + 1].status === StepStatus.valud
              ? 'bg-blue-600'
              : 'bg-gray-300'
          ]"
          aria-hidden="true"
        />
        <StepItem
          :step="step"
          :selectedStep="selectedStep"
          :disabled="stepDisabled(step, stepIdx)"
          @step-selected="onStepSelected(step.id)"
        />
      </li>
    </ol>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
// components
import StepItem from "./StepItem.vue";
// other
import { Step, StepStatus } from "./useSteps";

export default defineComponent({
  name: "StepsVertical",
  components: {
    StepItem
  },
  props: {
    steps: { type: Object as PropType<Step[]>, required: true },
    selectedStep: { type: Object as PropType<Step>, defualt: null }
  },
  emits: {
    "step-selected": String
  },
  setup(props, context) {
    const onStepSelected = (stepId: string) => {
      context.emit("step-selected", stepId);
    };

    // index of item with first error
    const errorIdx = computed(() => props.steps.findIndex(step => step.status === StepStatus.invalid));
    // const hasError = computed(() => props.steps.some(step => step.status === StepStatus.invalid));

    // check if step is disabled
    const stepDisabled = (step: Step, stepIdx: number) => {
      // disabled, if step is after step with error (and errorIdx found)
      if (errorIdx.value != -1 && stepIdx > errorIdx.value) return true;
      // never disabled if valid
      else if (step.status === StepStatus.valid) return false;
      // disabled if untouched and prev step not valid
      else if (
        step.status === StepStatus.untouched &&
        props.steps[Math.max(0, stepIdx - 1)].status !== StepStatus.valid
      )
        return true;
      else return false;
    };

    return { onStepSelected, StepStatus, stepDisabled };
  }
});
</script>
