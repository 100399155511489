
import { defineComponent, computed } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import ScheduleFormWizard from "@/features/schedules/form/ScheduleFormWizard.vue";

export default defineComponent({
  name: "ScheduleFormPage",
  components: {
    PageLayout,
    ScheduleFormWizard
  },
  setup() {
    const headerText = "Create schedule";

    // get previous route, that was not schedule details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null) {
        return "/schedules";
      } else return window.history.state.back;
    });

    return {
      headerText,
      prevRoute
    };
  }
});
