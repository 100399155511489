import { computed, reactive } from "vue";

export enum StepStatus {
  untouched = "untouched",
  valid = "valid",
  invalid = "invalid"
}

export interface Step {
  id: string;
  name: string;
  description: string;
  status: StepStatus;
}

export interface StepState {
  steps: Step[];
  selectedStep: Step | null;
}

export function createInitialStepState(steps?: Step[]) {
  return reactive({
    steps: steps ? steps : [],
    selectedStep: null
  } as StepState);
}

export default function useSteps(state: StepState) {
  // set state value to overwrite initial value
  const setState = (stepState: StepState) => {
    state = stepState;
  };

  // add step
  const addStep = (step: Step, index?: number) => {
    // at end of list
    if (!index) {
      state.steps.push(step);
    }
    // at index
    else {
      state.steps.splice(index, 0, step);
    }
  };

  // delete step
  const removeStep = (stepId: string) => {
    const index = state.steps.findIndex(step => step.id === stepId);
    if (index !== -1) state.steps.splice(index, 1);
  };

  // select step
  const selectStep = (stepId: string) => {
    const index = state.steps.findIndex(step => step.id === stepId);
    state.selectedStep = state.steps[index];
  };

  // complete step and select next step
  const completeSelectedStep = () => {
    // set step valid
    const index = state.steps.findIndex(step => step.id === state.selectedStep?.id);
    if (index !== -1) state.steps[index].status = StepStatus.valid;
    // select next step
    if (index + 1 < state.steps.length) selectStep(state.steps[index + 1].id);
  };

  // select previous step
  const selectPreviousStep = () => {
    const index = state.steps.findIndex(step => step.id === state.selectedStep?.id);
    selectStep(state.steps[Math.max(0, index - 1)].id);
  };

  // invalidate selected step
  const setSelectedStepInvalid = () => {
    const index = state.steps.findIndex(step => step.id === state.selectedStep?.id);
    if (index !== -1) state.steps[index].status = StepStatus.invalid;
  };

  // validate selected step
  const setSelectedStepValid = () => {
    const index = state.steps.findIndex(step => step.id === state.selectedStep?.id);
    if (index !== -1) state.steps[index].status = StepStatus.valid;
  };

  return {
    setState,
    steps: computed(() => state.selectedStep),
    selectedStep: computed(() => state.selectedStep),
    addStep,
    removeStep,
    selectStep,
    completeSelectedStep,
    selectPreviousStep,
    setSelectedStepInvalid,
    setSelectedStepValid
  };
}
