<template>
  <DetailsSection :header="'Work Areas'" :headerCaption="'Work areas considered for schedule'" :hasFullWidth="true">
    <template #body>
      <WorkAreaTable
        :workAreas="workAreas"
        :selectable="true"
        :selectedIdList="workAreaIds"
        @selected="onSelectWorkArea"
      />
      <div v-if="hasError" class="flex mt-4 bg-yellow-100">
        Please select at least one work area!
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-between">
        <BaseButton class="mr-3" @btn-click="onClose" :variant="'default'">Cancel</BaseButton>
        <div>
          <BaseButton class="mr-3" @btn-click="selectPreviousStep" :variant="'default'">Back</BaseButton>
          <BaseButton :variant="'primary'" @btn-click="onNext" :disabled="hasError">Next</BaseButton>
        </div>
      </div>
    </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent, watch, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import WorkAreaTable from "@/features/workAreas/table/WorkAreaTable.vue";
// store
import useWorkAreaStore from "@/store/useWorkAreaStore";
// other
import useScheduleForm from "@/features/schedules/form/useScheduleForm";
import { StepStatus } from "@/components/steps/useSteps";

export default defineComponent({
  name: "ScheduleForm",
  components: {
    DetailsSection,
    WorkAreaTable
  },
  emits: {
    "reset-form": null
  },
  setup(props, context) {
    //store
    const { workAreas } = useWorkAreaStore({ allWorkAreas: true });

    // schedule form hook
    const {
      selectedStep,
      completeSelectedStep,
      selectPreviousStep,
      workAreaIds,
      addWorkArea,
      removeWorkArea,
      setSelectedStepInvalid,
      setSelectedStepValid
    } = useScheduleForm();

    // check step validity, invalid if no employees are selected
    const checkStepValidity = () => {
      if (workAreaIds.value.length === 0) {
        // set error
        setSelectedStepInvalid();
        // set valid
      } else setSelectedStepValid();
    };

    // initialize workAreaIds, if no work areas are selected yet
    const initialiteWorkAreas = () => {
      if (workAreaIds.value.length === 0) {
        // select all work areas
        workAreas.value.forEach(workArea => addWorkArea(workArea.id, workAreas.value));
      }
      checkStepValidity();
    };
    // initialize on component load
    initialiteWorkAreas();
    // initialize when work areas change
    watch(workAreas, () => {
      initialiteWorkAreas();
    });

    // remove or add employee to work area
    const onSelectWorkArea = (workAreaId: string) => {
      // remove
      if (workAreaIds.value?.includes(workAreaId)) {
        removeWorkArea(workAreaId);
        // add
      } else {
        addWorkArea(workAreaId, workAreas.value);
      }
    };

    // set/unset step error, if no work areas are selected
    watch(workAreaIds, () => {
      checkStepValidity();
    });

    /**
     * General
     */

    const hasError = computed(() => selectedStep.value?.status === StepStatus.invalid);

    // Validate and create schedule
    const onNext = () => {
      completeSelectedStep();
    };

    // close form and reset data
    const onClose = () => {
      context.emit("reset-form");
    };

    return {
      onNext,
      selectPreviousStep,
      onClose,
      hasError,
      workAreas,
      workAreaIds,
      onSelectWorkArea
    };
  }
});
</script>
