
import { defineComponent, ref, watch } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import DatePickerInput from "@/components/datepicker/DatePickerInput.vue";
// other
import useScheduleForm from "@/features/schedules/form/useScheduleForm";

export default defineComponent({
  name: "ScheduleForm",
  components: {
    DetailsSection,
    DatePickerInput
  },
  emits: {
    "reset-form": null
  },
  setup(props, context) {
    // schedule form hook
    const {
      completeSelectedStep,
      validateSchema,
      setVaidateSchema,
      scheduleSchema,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      name,
      setName
    } = useScheduleForm();

    // update name
    const scheduleName = ref(name.value);
    watch(name, newName => {
      scheduleName.value = newName;
    });
    const onChangeName = () => setName(scheduleName.value);

    // update dates
    const onSelectStartDate = (startDate: Date) => {
      setStartDate(startDate);
    };
    const onSelectEndDate = (endDate: Date) => {
      setEndDate(endDate);
    };

    // Validate and create schedule
    const onNext = () => {
      const formData = { name: scheduleName.value, startDate: startDate.value, endDate: endDate.value };
      scheduleSchema
        .validate(formData)
        .then(() => {
          completeSelectedStep();
        })
        .catch(e => {
          setVaidateSchema(true);
          console.log(e);
        });
    };

    // close form and reset data
    const onClose = () => {
      setVaidateSchema(false);
      context.emit("reset-form");
    };

    return {
      validateSchema,
      scheduleSchema,
      startDate,
      endDate,
      scheduleName,
      onSelectStartDate,
      onSelectEndDate,
      onNext,
      onClose,
      onChangeName
    };
  }
});
