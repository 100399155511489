<template>
  <DetailsSection :header="'Employees'" :headerCaption="'Employees considered for schedule'" :hasFullWidth="true">
    <template #body>
      <EmployeeTable
        :employees="employees"
        :selectable="true"
        :selectedIdList="employeeIds"
        @selected="onSelectEmployee"
      />
      <div v-if="hasError" class="flex mt-4 bg-yellow-100">
        Please select at least one employee!
      </div>
    </template>

    <template #footer>
      <div class="flex w-full justify-between">
        <BaseButton class="mr-3" @btn-click="onClose" :variant="'default'">Cancel</BaseButton>
        <div>
          <BaseButton class="mr-3" @btn-click="selectPreviousStep" :variant="'default'">Back</BaseButton>
          <BaseButton :variant="'primary'" @btn-click="onNext" :disabled="hasError">Next</BaseButton>
        </div>
      </div>
    </template>
  </DetailsSection>
</template>

<script lang="ts">
import { defineComponent, watch, computed } from "vue";
// components
import DetailsSection from "@/components/details/DetailsSection.vue";
import EmployeeTable from "@/features/employees/table/EmployeeTable.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";
import useEmployeeWorkAreaStore from "@/store/useEmployeeWorkAreaStore";
// other
import useScheduleForm from "@/features/schedules/form/useScheduleForm";
import { StepStatus } from "@/components/steps/useSteps";

export default defineComponent({
  name: "ScheduleForm",
  components: {
    DetailsSection,
    EmployeeTable
  },
  emits: {
    "reset-form": null
  },
  setup(props, context) {
    // schedule form hook
    const {
      selectedStep,
      completeSelectedStep,
      selectPreviousStep,
      employeeIds,
      workAreaIds,
      addEmployee,
      removeEmployee,
      setSelectedStepInvalid,
      setSelectedStepValid
    } = useScheduleForm();

    //store
    const { employees } = useEmployeeStore({ allEmployees: true });
    const { employeeWorkAreas } = useEmployeeWorkAreaStore({
      allEmployeeWorkAreas: true
    });
    // get assignments of selected work areas
    const employeeWorkAreasFiltered = computed(() =>
      employeeWorkAreas.value.filter(item => workAreaIds.value.includes(item.work_area_id))
    );

    // check step validity, invalid if no employees are selected
    const checkStepValidity = () => {
      if (employeeIds.value.length === 0) {
        // set error
        setSelectedStepInvalid();
        // set valid
      } else setSelectedStepValid();
    };

    // initialize employeeIds, with those that are assigned to the selected work areas
    const initialiteEmployees = () => {
      // initialize employees, if none are selected yet
      if (employeeIds.value.length === 0) {
        // select employees that belong to work area
        const employeeIds = employeeWorkAreasFiltered.value.map(item => item.employee_id);
        employeeIds.forEach(employeeId => addEmployee(employeeId));
      }
      // set valid status
      checkStepValidity();
    };

    // initialize on component load
    initialiteEmployees();

    // reinitialize if work areas change
    watch(employeeWorkAreasFiltered, () => {
      initialiteEmployees();
    });

    // set/unset step error, if no work areas are selected
    watch(employeeIds, () => {
      checkStepValidity();
    });

    // remove or add employee to work area
    const onSelectEmployee = (employeeId: string) => {
      // remove
      if (employeeIds.value?.includes(employeeId)) {
        removeEmployee(employeeId);
        // add
      } else {
        addEmployee(employeeId);
      }
    };

    // step error, if no employees are selected
    const hasError = computed(() => selectedStep.value?.status === StepStatus.invalid);

    // Validate and create schedule
    const onNext = () => {
      completeSelectedStep();
    };

    // close form and reset data
    const onClose = () => {
      context.emit("reset-form");
    };

    return {
      onNext,
      selectPreviousStep,
      onClose,
      hasError,
      employees,
      employeeIds,
      onSelectEmployee
    };
  }
});
</script>
