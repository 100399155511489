<template>
  <PageLayout :headerText="headerText">
    <template #header-addon-left>
      <router-link :to="prevRoute" class="flex items-center p-0.5 mr-2">
        <div class="h-5 w-5 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
      </router-link>
    </template>

    <template #main>
      <ScheduleFormWizard class="mt-2" />
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import PageLayout from "@/components/PageLayout.vue";
import ScheduleFormWizard from "@/features/schedules/form/ScheduleFormWizard.vue";

export default defineComponent({
  name: "ScheduleFormPage",
  components: {
    PageLayout,
    ScheduleFormWizard
  },
  setup() {
    const headerText = "Create schedule";

    // get previous route, that was not schedule details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null) {
        return "/schedules";
      } else return window.history.state.back;
    });

    return {
      headerText,
      prevRoute
    };
  }
});
</script>
