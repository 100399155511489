
import { computed, defineComponent, PropType } from "vue";
// components
import StepItem from "./StepItem.vue";
// other
import { Step, StepStatus } from "./useSteps";

export default defineComponent({
  name: "StepsVertical",
  components: {
    StepItem
  },
  props: {
    steps: { type: Object as PropType<Step[]>, required: true },
    selectedStep: { type: Object as PropType<Step>, defualt: null }
  },
  emits: {
    "step-selected": String
  },
  setup(props, context) {
    const onStepSelected = (stepId: string) => {
      context.emit("step-selected", stepId);
    };

    // index of item with first error
    const errorIdx = computed(() => props.steps.findIndex(step => step.status === StepStatus.invalid));
    // const hasError = computed(() => props.steps.some(step => step.status === StepStatus.invalid));

    // check if step is disabled
    const stepDisabled = (step: Step, stepIdx: number) => {
      // disabled, if step is after step with error (and errorIdx found)
      if (errorIdx.value != -1 && stepIdx > errorIdx.value) return true;
      // never disabled if valid
      else if (step.status === StepStatus.valid) return false;
      // disabled if untouched and prev step not valid
      else if (
        step.status === StepStatus.untouched &&
        props.steps[Math.max(0, stepIdx - 1)].status !== StepStatus.valid
      )
        return true;
      else return false;
    };

    return { onStepSelected, StepStatus, stepDisabled };
  }
});
